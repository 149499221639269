import * as React from 'react';
import Grid from 'styleguide/components/Grid/Grid';
import Footer from 'styleguide/components/Footer/Footer';
import Collapse from 'styleguide/components/Collapse/Collapse';
import Meta from 'styleguide/components/Meta/Meta';
import TitleContainer from 'bundles/App/pages/Account/TitleContainer/TitleContainer';
import A from 'styleguide/components/Links/A';

const PaperShortagePage = () => {
  const faqs = [
    {
      question: 'What supply chain disruptions are affecting Printivity?',
      answer: (
        <div className="px-4">
          There is currently an international paper stock shortage that is impacting Printivity and other
          printers.&nbsp;
          <A
            href="https://www.bloomberg.com/news/articles/2021-09-24/paper-shortage-hits-retailers-printing-catalogs-to-advertise-2021-holiday-gifts"
            color="blue"
            underline="always"
            className="!text-sm md:!text-base"
          >
            You can read more about this phenomenon here.
          </A>
        </div>
      ),
    },
    {
      question: 'What’s causing this paper shortage?',
      answer: (
        <div className="px-4">
          The current paper shortage has been caused by a variety of factors, including reduced production
          capacity at paper mills and continuing long delays at cargo ports.
        </div>
      ),
    },
    {
      question: 'Does this mean you don’t have any paper?',
      answer: (
        <div className="px-4">
          Printivity has been working closely with our suppliers to maintain as much inventory as possible.
          However, we will run short of specific paper types from time to time if supply deliveries are
          delayed or fall short of expectations.
        </div>
      ),
    },
    {
      question: 'What types of paper are affected by this shortage?',
      answer: (
        <div className="px-4">
          All paper types can be affected by the current supply chain disruption, and specific inventory
          levels can change day by day.
        </div>
      ),
    },
    {
      question: 'How long will this last?',
      answer: (
        <div className="px-4">
          We expect the paper supply chain to be affected for at least the next several months
        </div>
      ),
    },
    {
      question: 'What should I do?',
      answer: (
        <div className="px-4">
          The most important advice we have is to keep your whole team fully informed. Prepare your clients
          for potential for delays and/or the potential need to change paper types according to current
          inventory. Keep Printivity informed of any important deadlines on your end. If you’re flexible about
          switching paper types to avoid delays, let your Customer Service Representative know!
        </div>
      ),
    },
    {
      question: 'What if I have a critical deadline?',
      answer: (
        <div className="px-4">
          Over the last two years Printivity has seen disruptions with supply chains, all major shipping
          carriers (UPS, FedEx, and USPS), and production schedules. Your order may experience a delay, and
          for this reason all delivery dates are estimated. If you have an event or need your product to
          arrive by a particular date,{' '}
          <b>
            we strongly recommend that you choose an earlier delivery date to make sure you have a buffer to
            allow for a possible delivery delay.
          </b>
        </div>
      ),
    },
    {
      question: 'What happens if you run out of the paper I ordered?',
      answer: (
        <div className="px-4">
          If Printivity out of the paper you ordered, and we do not expect to receive a new shipment within a
          few days, a Customer Service Representative will contact you about switching to another paper type
          (at no additional cost).
        </div>
      ),
    },
  ];

  return (
    <>
      <Meta
        title="Paper Shortage | Printivity"
        description="Printivity faces international paper shortage"
        canonical="https://www.printivity.com/paper-shortage"
      />
      <TitleContainer classes="lg:!text-7xl !p-0" title="International paper shortages" />
      <Grid.Container>
        <Grid fullWidth className="!p-0">
          <Grid.Row>
            <Grid.Col sm={12}>
              <Grid.Row>
                <Grid.Col>
                  <Collapse>
                    {faqs.map((faq: { question: string; answer: JSX.Element }, i: number) => (
                      <Collapse.Item
                        title={
                          <h2 className="m-4 font-hvBold text-sm md:text-base lg:text-xl">{faq.question}</h2>
                        }
                        body={faq.answer}
                        classNameBody="text-xs md:text-base"
                        key={i}
                        isWide
                        withPadding
                      />
                    ))}
                  </Collapse>
                </Grid.Col>
              </Grid.Row>
            </Grid.Col>
          </Grid.Row>
        </Grid>
      </Grid.Container>
      <Footer />
    </>
  );
};

export default PaperShortagePage;
